import React from "react";
import styles from "./PrivacyPolicy.module.css";

const PrivacyPolicy = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Privacy Policy for PSTET 1 and 2</h1>
      <p className={styles.updateDate}>Last updated: 14-11-2024</p>

      <section className={styles.section}>
        <h2 className={styles.heading}>1. Information Collection</h2>
        <p className={styles.text}>
          To provide you with personalized services, we collect the following
          information when you create an account:
        </p>
        <ul className={styles.list}>
          <li>
            <strong>Personal Information:</strong> When you register, we ask for
            your name, email address, and mobile number.
          </li>
          <li>
            <strong>Quiz Data:</strong> Information regarding your quiz results,
            including scores and progress, is stored to help you track your
            preparation.
          </li>
        </ul>
      </section>

      <section className={styles.section}>
        <h2 className={styles.heading}>2. Data Usage</h2>
        <p className={styles.text}>
          We use the collected information solely to:
        </p>
        <ul className={styles.list}>
          <li>
            Provide personalized test preparation and track your progress.
          </li>
          <li>Improve the app's functionality and user experience.</li>
        </ul>
      </section>

      <section className={styles.section}>
        <h2 className={styles.heading}>3. Data Security</h2>
        <p className={styles.text}>
          We take your data security seriously. All personal information and
          quiz data are stored securely in our database. Passwords are stored in
          encrypted form, ensuring your account remains protected.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.heading}>4. Data Sharing</h2>
        <p className={styles.text}>
          We do not share, sell, or disclose your data to any third parties.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.heading}>5. Account Deletion</h2>
        <p className={styles.text}>
          You have the right to delete your account at any time. Deleting your
          account will permanently remove all your information from our
          database, including your personal details and quiz history.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.heading}>6. Changes to this Privacy Policy</h2>
        <p className={styles.text}>
          We may update this Privacy Policy periodically. We will notify you of
          any significant changes by posting the updated policy in the app.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.heading}>7. Contact Us</h2>
        <p className={styles.text}>
          If you have any questions or concerns about this Privacy Policy or how
          we handle your information, please contact us at
        </p>
        <p>Email: sondhwebcreators@gmail.com</p>
        <p>Address: Sondh Web Creators, Amritsar, Punjab, India</p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
